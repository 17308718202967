import { BrowserRouter as Router, Routes, Route,  } from "react-router-dom";
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/routes/PrivateRoute';
import Signup from './components/authentication-flow/Signup';
import Login from './components/authentication-flow/Login';
import Chatbubble from './components/chatbubble/Chatbubble';
import ChatBubbleEmbed from './components/chatbubble/ChatBubbleEmbed';
import Home from './components/Home/Home';


function App() {
  return (
    <AuthProvider>
        <Router>
            <Routes>
                <Route path='/signup' element={ <Signup /> } ></Route>
                <Route path='/login' element={ <Login /> } ></Route>
                <Route exact path='/' element={ <PrivateRoute><Home /></PrivateRoute> }></Route>
                
                <Route exact path='/chatbubble/editor' element={ <PrivateRoute><Chatbubble /></PrivateRoute> } ></Route>
                <Route exact path='/chatbubble/embed' element={ <ChatBubbleEmbed /> } ></Route>


                {/* <Route exact path='/admin' element={ <AdminRoute><AdminCourses /></AdminRoute> } ></Route>
                <Route exact path='/admin/livebeginnerscourse' element={ <AdminRoute><AdminLiveBeginnersCourse /></AdminRoute> } ></Route>
                <Route exact path='/admin/courses' element={ <AdminRoute><AdminCourses /></AdminRoute> } ></Route> */}
            </Routes>
        </Router>
    </AuthProvider>
);
}

export default App;
