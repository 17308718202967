import React, { useEffect, useState, useRef } from 'react';
import { collection, getDoc, getDocs, doc, query, where, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import closeIcon from '../../images/close.png';

export default function ChatBubbleEmbed() {
    
    const queryParams = new URLSearchParams(window.location.search)
    const publickey = queryParams.get("publickey")
    const chatid = queryParams.get("chatid")
    
    const chatBubble = useRef();
    const chatPopup = useRef();
    const closeButton1 = useRef();
    const closeButton2 = useRef();
    const contactForm = useRef();
    const thankYouMessage = useRef();
    
    const [loading, setLoading] = useState(true);
    const [bubbleData, setBubbleData] = useState();
    
    function handleToggle(ref, className) {
        ref.current.classList.toggle(className);
    }

    useEffect(() => {
        getDoc(doc(db, 'users', publickey, 'chat-bubbles', chatid))
        .then((doc) => {
            if (!doc.exists()) {
                return <div>you can't access this page</div>
            }
            setBubbleData(doc);
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        setTimeout(() => {
            handleToggle(chatBubble, "inactive");
        }, 1000);
    }, [])


    if (!publickey) {
        return <div>you can't access this page: warning level 2</div>
    } else if (!chatid) { return <div>you can't access this page: warning level 1</div> }
    


    else if (loading) {
        return <div>you can't access this page</div>
    }

    else if (!loading) {

        function toggleChatPopup() {
            handleToggle(chatBubble, "opened");
            handleToggle(chatBubble, "closed");
            handleToggle(chatPopup, "closed");
        }
        
        return (
            <>
                <div id="chatPopupWrapper" className="contact-popup-wrapper">
                <div ref={chatPopup} id="chatPopup" className="contact-form-wrapper closed">
                    <img onClick={toggleChatPopup} ref={closeButton2} id="closeButton" className="contact-close-icon closeButton" src={closeIcon} />
                    <h1 className="contact-title">{bubbleData.data().formTitle}</h1>
                    <p className="contact-description">
                        {bubbleData.data().formDescription}
                    </p>
                    <form ref={contactForm} id="contactForm" action="" className="contact-form">
                        <input className="contact-form-input" type="text" placeholder="Name" data-name />
                        <input className="contact-form-input" type="email" placeholder="Email" data-email required />
                        <input className="contact-form-input" type="text" placeholder="Subject" data-subject required />
                        <textarea className="contact-form-textarea" name="" id="" cols="30" rows="10" placeholder="Message" data-message required></textarea>
                        <button className="contact-form-submit" type="submit" style={{ color: bubbleData.data().formButtonTextColor }}>{bubbleData.data().formButtonText}</button>
                    </form>
                    <p className="contact-description">Powered by SnypeMedia</p>
            
                    <div ref={thankYouMessage} id="thankYouMessage" className="thank-you-wrapper closed">
                        <img onClick={toggleChatPopup} ref={closeButton1} id="closeButton" className="contact-close-icon closeButton" src={closeIcon} />
                        <div className="thank-you-content-wrapper">
                            <h1 className="contact-title">Thank you!</h1>
                            <p className="contact-description">
                            {bubbleData.data().successDescription}
                            </p>
                        </div>
                        <svg style={{ width: '80%' }} className="successImageSvg" xmlns="http://www.w3.org/2000/svg" width="570" height="511.67482" viewBox="0 0 570 511.67482">
                            <path d="M879.99927,389.83741a.99678.99678,0,0,1-.5708-.1792L602.86963,197.05469a5.01548,5.01548,0,0,0-5.72852.00977L322.57434,389.65626a1.00019,1.00019,0,0,1-1.14868-1.6377l274.567-192.5918a7.02216,7.02216,0,0,1,8.02-.01318l276.55883,192.603a1.00019,1.00019,0,0,1-.57226,1.8208Z" transform="translate(-315 -194.16259)" fill="#3f3d56"/>
                            <polygon points="23.264 202.502 285.276 8.319 549.276 216.319 298.776 364.819 162.776 333.819 23.264 202.502" fill="#e6e6e6"/>
                            <path d="M489.25553,650.70367H359.81522a6.04737,6.04737,0,1,1,0-12.09473H489.25553a6.04737,6.04737,0,1,1,0,12.09473Z" transform="translate(-315 -194.16259)" fill={bubbleData.data().successImageColor}/>
                            <path d="M406.25553,624.70367H359.81522a6.04737,6.04737,0,1,1,0-12.09473h46.44031a6.04737,6.04737,0,1,1,0,12.09473Z" transform="translate(-315 -194.16259)" fill={bubbleData.data().successImageColor}/>
                            <path d="M603.96016,504.82207a7.56366,7.56366,0,0,1-2.86914-.562L439.5002,437.21123v-209.874a7.00817,7.00817,0,0,1,7-7h310a7.00818,7.00818,0,0,1,7,7v210.0205l-.30371.12989L606.91622,504.22734A7.61624,7.61624,0,0,1,603.96016,504.82207Z" transform="translate(-315 -194.16259)" fill="#fff"/>
                            <path d="M603.96016,505.32158a8.07177,8.07177,0,0,1-3.05957-.59863L439.0002,437.54521v-210.208a7.50851,7.50851,0,0,1,7.5-7.5h310a7.50851,7.50851,0,0,1,7.5,7.5V437.68779l-156.8877,66.999A8.10957,8.10957,0,0,1,603.96016,505.32158Zm-162.96-69.1123,160.66309,66.66455a6.1182,6.1182,0,0,0,4.668-.02784l155.669-66.47851V227.33721a5.50653,5.50653,0,0,0-5.5-5.5h-310a5.50653,5.50653,0,0,0-5.5,5.5Z" transform="translate(-315 -194.16259)" fill="#3f3d56"/>
                            <path d="M878,387.83741h-.2002L763,436.85743l-157.06982,67.07a5.06614,5.06614,0,0,1-3.88038.02L440,436.71741l-117.62012-48.8-.17968-.08H322a7.00778,7.00778,0,0,0-7,7v304a7.00779,7.00779,0,0,0,7,7H878a7.00779,7.00779,0,0,0,7-7v-304A7.00778,7.00778,0,0,0,878,387.83741Zm5,311a5.002,5.002,0,0,1-5,5H322a5.002,5.002,0,0,1-5-5v-304a5.01106,5.01106,0,0,1,4.81006-5L440,438.87739l161.28027,66.92a7.12081,7.12081,0,0,0,5.43994-.03L763,439.02741l115.2002-49.19a5.01621,5.01621,0,0,1,4.7998,5Z" transform="translate(-315 -194.16259)" fill="#3f3d56"/>
                            <path d="M602.345,445.30958a27.49862,27.49862,0,0,1-16.5459-5.4961l-.2959-.22217-62.311-47.70752a27.68337,27.68337,0,1,1,33.67407-43.94921l40.36035,30.94775,95.37793-124.38672a27.68235,27.68235,0,0,1,38.81323-5.12353l-.593.80517.6084-.79346a27.71447,27.71447,0,0,1,5.12353,38.81348L624.36938,434.50586A27.69447,27.69447,0,0,1,602.345,445.30958Z" transform="translate(-315 -194.16259)" fill={bubbleData.data().successImageColor}/>
                        </svg>
                        <p className="contact-description">Powered by SnypeMedia</p>
                    </div>
                </div>
                <div onClick={toggleChatPopup} ref={chatBubble} id="chatBubble" className="chat-bubble closed inactive"></div>
                </div>



                <style dangerouslySetInnerHTML={{__html: `
                .contact-popup-wrapper {
                    position: fixed;
                    bottom: 15px;
                    right: 15px;
                    z-index: 10000;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    color: black;
                }
                
                .chat-bubble {
                    background-color: ${bubbleData.data().bubbleBackgroundColor};
                    color: ${bubbleData.data().bubbleTextColor};
                    font-weight: 600;
                    font-size: 17px;
                    height: 60px;
                    width: 120px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 30px 30px 0px 30px;
                    transition: all 100ms ease-in-out;
                    box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.24);
                    cursor: pointer;
                }
                
                .chat-bubble.inactive {
                    transform: scale3d(0,0,0);
                    transform-origin: bottom right;
                    transition: all 100ms ease-in-out;
                }
                
                .chat-bubble.opened {
                    border-radius: 30px 0px 30px 30px;
                    width: 60px;
                    transition: all 100ms ease-in-out;
                }
                
                .chat-bubble.closed:before {
                    content: '${bubbleData.data().bubbleText}';
                }
                
                .contact-form-wrapper {
                    color: ${bubbleData.data().formTextColor};
                    z-index: 1000;
                    position: absolute;
                    bottom: 60px;
                    background-color: ${bubbleData.data().formBackgroundColor};
                    margin-bottom: 10px;
                    border-radius: 30px 30px 0px 30px;
                    padding: 40px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    transition: all 250ms ease-in-out;
                    box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.24);
                }
                
                .contact-form-wrapper.closed {
                    transform: scale3d(0,0,0);
                    transform-origin: bottom right;
                    transition: all 250ms ease-in-out;
                    /* animation: hidePopup 2s ease-in-out forwards; */
                }
                
                .contact-description {
                    max-width: 30ch;
                    font-weight: 500;
                    text-align: center;
                    font-size: 15px;
                    margin-bottom: 20px;
                }
                
                .contact-form {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                }
                
                .contact-form-input,
                .contact-form-textarea {
                    border: none;
                    background-color: #F8FBFC;
                    margin-bottom: 10px;
                    border-radius: 10px;
                    height: 60px;
                    padding: 0 20px;
                    font-weight: 700;
                }
                
                .contact-form-textarea {
                    padding-top: 20px;
                    height: 100px;
                    resize: none;
                }
                
                input:focus,
                textarea:focus {
                    outline: none;
                }
                
                input::placeholder, 
                textarea::placeholder {
                    color: #757575;
                    font-weight: 800;
                    font-size: 15px;
                }
                
                .contact-form-submit {
                    height: 40px;
                    color: ${bubbleData.data().formButtonTextColor};
                    background-color: ${bubbleData.data().formButtonBackgroundColor};
                    border-radius: 10px;
                    font-weight: 800;
                    border: none;
                    margin: 20px 0;
                    padding: 0;
                }
                
                .contact-close-icon {
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    right: 25px;
                    top: 25px;
                    cursor: pointer;
                }
                
                .thank-you-wrapper {
                    background-color: white;
                    border-radius: 30px 30px 0px 30px;
                    padding: 40px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.24);
                    position: absolute;
                    width: calc(100% - 80px);
                    height: calc(100% - 80px);
                    bottom: 0;
                    margin: 0;
                    opacity: 100;
                    transition: all 100ms ease-in-out;
                }
                
                .thank-you-wrapper.closed {
                    display: none;
                    opacity: 0;
                    transition: all 100ms ease-in-out;
                }
                
                .thank-you-content-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                
                .contact-chat-suggestions-wrapper {
                    position: absolute;
                    bottom: 60px;
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                    margin-bottom: 10px;
                    width: 300px;
                }
                
                .chat-suggestion {
                    background-color: rgba(255, 255, 255, 0.582);
                    border-radius: 30px;
                    transition: all 100ms ease-in-out;
                    box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.24);
                    cursor: pointer;
                    padding: 15px 20px;
                    display: flex;
                    align-items: center;
                    /* opacity: 0.9; */
                    border: 2px solid white;
                    margin-top: 10px;
                }
                
                .chat-suggestion:hover {
                    background-color: white;
                }
                
                .chat-suggestion:hover .suggestion-right-arrow {
                    margin-left: 15px;
                    opacity: 100;
                } 
                
                .suggestion-text {
                    font-weight: 600;
                    font-size: 15px;
                    margin: 0;
                    padding: 0;
                    max-width: 30ch;
                    line-height: 20px;
                }
                
                .suggestion-right-arrow {
                    height: 25px;
                    /* margin-left: 15px; */
                    margin-left: -25px;
                    opacity: 0;
                    transition: all 100ms ease-in-out;
                }
                
                .remove-chat-suggestions {
                    background-color: rgba(255, 255, 255, 0.699);
                    border-radius: 30px;
                    transition: all 100ms ease-in-out;
                    box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.24);
                    cursor: pointer;
                    padding: 5px 15px;
                    display: flex;
                    align-items: center;
                    margin-right: 10px;;
                }
                
                .remove-chat-suggestions:hover {
                    background-color: white;
                }
                
                .remove-chat-suggestions h3 {
                    font-weight: 800;
                    font-size: 10px;
                }
                
                .contact-chat-suggestions-wrapper.closed {
                    transform: translateX(100%);
                    opacity: 0;
                    transition: all 100ms ease-in-out;
                }
                
                .contact-chat-suggestions-wrapper {
                    transition: all 100ms ease-in-out;
                    transform: translateX(0%);
                    opacity: 1;
                }
                
                .contact-chat-suggestions-wrapper.clear {
                    display: none;
                }
                
                /* Animations */
                
                @keyframes hidePopup {
                    0% {
                        opacity: 1;
                    }
                    100% {
                        opacity: 0;
                    }
                }
            `}} />
            </>
      )
    }
}
