import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';


export default function SideMenu(props) {

    const [logOutError, setLogOutError] = useState('');
    const { logout, currentUser } = useAuth();
    const navigate = useNavigate();

    function checkForActiveLink(link) {
        if (link === props.currentTab) return 'active';
    }

    function handleLogout() {
        setLogOutError('');

        logout()
        .then(() => {
            navigate('/login');
        })
    }

    // function handleSettings() {

    //     let functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink')
    //     functionRef({ returnUrl: window.location.href })
    //     .then((data) => {
    //         window.location.assign(data.data.url);
    //     })
    // }

    return (
        <div className="side-menu">
            <div className="menu-collections-wrapper">
                <div className="menu-collection">
                <h2 className="logo">SnypeSolutions</h2>
                    <Link to='/' style={{ color: 'black', textDecoration: 'none' }} >
                    <div className={'menu-link-wrapper ' + checkForActiveLink("home")}>
                        <i className="iconly-Home icli menu-link-icon"></i>
                        <p className="menu-link-text">Dashboard</p>
                    </div>
                    </Link>
                    <Link to='/chatbubble/editor' style={{ color: 'black', textDecoration: 'none' }} >
                        <div className={'menu-link-wrapper ' + checkForActiveLink("chatbubble")}>
                            <i className="iconly-Chat icli menu-link-icon"></i>
                            <p className="menu-link-text">Chat Bubble</p>
                        </div>
                    </Link>
                </div>
                <div className="menu-collection">
                    <div className={'menu-link-wrapper ' + checkForActiveLink("settings")}>
                    <i className="iconly-Setting icli menu-link-icon"></i>
                        <p className="menu-link-text">Settings</p>
                    </div>
                    <div onClick={handleLogout} className="menu-link-wrapper">
                        <i className="iconly-Logout icli menu-link-icon"></i>
                        <p className="menu-link-text">Logout</p>
                        {logOutError && <div className="error">{logOutError}</div>}
                    </div>
                </div>
            </div>
            <div className="menu-sideline"></div>
        </div>
    )
}
