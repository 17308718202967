import React, { useState } from 'react';
import closeIcon from '../../images/close.png';

export default function FormPreview(props) {
      


    
    return (
        <>
            <div id="chatPopup" className="contact-form-wrapper">
                <img id="closeButton" className="contact-close-icon closeButton" src={closeIcon} />
                <h1 className="contact-title">{props.formTitle}</h1>
                <p className="contact-description" style={{ marginBottom: "20px" }}>
                    {props.formDescription}
                </p>
                <form id="contactForm" className="contact-form">
                    <input className="contact-form-input" type="text" placeholder="Name" data-name />
                    <input className="contact-form-input" type="email" placeholder="Email" data-email required />
                    <input className="contact-form-input" type="text" placeholder="Subject" data-subject required />
                    <textarea className="contact-form-textarea" cols="30" rows="10" placeholder="Message" data-message required></textarea>
                    <button className="contact-form-submit" type="button" style={{ color: props.formButtonTextColor }}>{props.formButtonText}</button>
                </form>
                <p className="contact-description">Powered by SnypeMedia</p>
            </div>

            <style dangerouslySetInnerHTML={{__html: `
                .contact-form-wrapper {
                    color: ${props.formTextColor};
                    z-index: 1000;
                    position: relative;
                    background-color: ${props.formBackgroundColor};
                    margin-bottom: 10px;
                    border-radius: 30px 30px 0px 30px;
                    padding: 40px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    transition: all 250ms ease-in-out;
                    box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.24);
                }
                .contact-close-icon {
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    right: 25px;
                    top: 25px;
                    cursor: pointer;
                }
                .contact-description {
                    max-width: 30ch;
                    font-weight: 500;
                    text-align: center;
                    font-size: 15px;
                }
                .contact-form {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                }
                .contact-form-input,
                .contact-form-textarea {
                    border: none;
                    background-color: #F8FBFC;
                    margin-bottom: 10px;
                    border-radius: 10px;
                    height: 60px;
                    padding: 0 20px;
                    font-weight: 700;
                }
                .contact-form-textarea {
                    padding-top: 20px;
                    height: 100px;
                    resize: none;
                }
                .contact-form-submit {
                    height: 40px;
                    color: ${props.formButtonTextColor};
                    background-color: ${props.formButtonBackgroundColor};
                    border-radius: 10px;
                    font-weight: 800;
                    border: none;
                    margin: 20px 0;
                    padding: 0;
                }
            `}} />
        </>
  )
}
