import React, { useState, useEffect } from 'react';
import Sidemenu from '../Sidemenu';
import { useAuth } from '../../contexts/AuthContext';
import { collection, getDoc, getDocs, doc, query, where } from 'firebase/firestore';
import { db } from '../../firebase';

export default function Home() {

    const { currentUser } = useAuth();
    let user = currentUser.uid;

  
    return (
        <div className="page-wrapper">
            <Sidemenu currentTab='home'/>
            <div className="application-wrapper horizontal">
            </div>
        </div>
    )
}
