import React, { useState } from 'react';

export default function BubblePreview(props) {
  
    const [bubbleState, setBubbleState] = useState(true);
    

    function handleChangeState() {
        setBubbleState(!bubbleState)
    }

    return (
        <>
            <div onClick={handleChangeState} id="chatBubble" className={"chat-bubble " + `${bubbleState}`}></div>

            <style dangerouslySetInnerHTML={{__html: `
                .chat-bubble { 
                    background-color: ${props.bubbleBackgroundColor};
                    color: ${props.bubbleTextColor};
                    font-weight: 600;
                    font-size: 17px;
                    height: 60px;
                    width: 120px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 30px 30px 0px 30px;
                    transition: all 100ms ease-in-out;
                    box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.24);
                    cursor: pointer;
                }
                .chat-bubble.inactive {
                    transform: scale3d(0,0,0);
                    transform-origin: bottom right;
                    transition: all 100ms ease-in-out;
                }
                .chat-bubble.false {
                    border-radius: 30px 0px 30px 30px;
                    width: 60px;
                    transition: all 100ms ease-in-out;
                }
                .chat-bubble.true::before {
                    content: '${props.bubbleText}';
                }
            `}} />
        </>
  )
}
