import React, { useRef, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { setDoc, doc, addDoc, getDoc, collection } from 'firebase/firestore';
import googleLogo from '../../images/google-logo.png';

export default function Signup() {

    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const nameRef = useRef();
    const { signupWithEmail, authenticateWithGoogle } = useAuth();
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function handleSubmit(e) {
        e.preventDefault();

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match')
        }

        setError('');
        setLoading(true);

        let user = ''
        signupWithEmail(emailRef.current.value, passwordRef.current.value)
        .then((userCredentials) => {
            user = userCredentials.user;
            setDoc(doc(db, 'users', user.uid), {
                Email: emailRef.current.value,
                Name: nameRef.current.value,
            })
        })
        .then(() => {
            addDoc(collection(db, 'users', user.uid, 'chat-bubbles'), {
                bubbleBackgroundColor: "#ffffff",
                bubbleText: "Contact",
                bubbleTextColor: "#000000",
                formBackgroundColor: "#ffffff",
                formButtonBackgroundColor: "000000",
                formButtonText: "send",
                formButtonTextColor: "#ffffff",
                formDescription: "Give a brief description of what you are looking for and we will contact you as soon as possible",
                formTextColor: "#000000",
                formTitle: "Contact",
                successBackgroundColor: "#ffffff",
                successDescription: "thank you for your message",
                successImageColor: "#000000",
                successTextColor: "#000000",
                successTitle: "Thanks!"
            })
        })
        .then(() => {
            navigate('/');  
        })
        .catch(error => {
            setError('Failed to create an account, ' + error.message)
        })
        setLoading(false);

    }

    function handleGoogleSignin() {
        authenticateWithGoogle();
    }

    return (
        <div className="auth-page-wrapper">
        <div className="form-wrapper auth-form-wrapper sign-up-card">
            <h2 className="auth-header">Sign Up</h2>
            {error && <div className="error">{error}</div>}
            {/* <div onClick={handleGoogleSignin} className="sign-in-with-google">
                <img src={googleLogo} alt="" />
                <h2>Sign up with Google</h2>
            </div> */}
            <form onSubmit={handleSubmit} className="auth-form sign-up-form">
            <div className="auth-form-group">
                    <label htmlFor="" className="auth-form-label">Name</label>
                    <input type="text" ref={nameRef} className="auth-form-input" required/>
                </div>
                <div className="auth-form-group">
                    <label htmlFor="" className="auth-form-label">Email</label>
                    <input type="email" ref={emailRef} className="auth-form-input" required/>
                </div>
                <div className="auth-form-group">
                    <label htmlFor="" className="auth-form-label">Password</label>
                    <input type="password" ref={passwordRef} className="auth-form-input" required/>
                </div>
                <div className="auth-form-group">
                    <label htmlFor="" className="auth-form-label">Password Confirmation</label>
                    <input type="password" ref={passwordConfirmRef} className="auth-form-input" required/>
                </div>
                <Link style={{ color: 'black' }} to='/login'><button type="button" style={{ marginRight: '5px' }} className="secundary-button">Login</button></Link>
                <button disabled={loading} type="submit" className="primary-button button-stretch">Create Account</button>
            </form>
        </div>
        {/* <div className="switch-auth-domain">
            Already have an account? <Link style={{ color: 'var(--primary-clr)' }} to='/login'>Log in</Link>
        </div> */}
        </div>
    )
}
