import React, { useState, useRef } from 'react';
import Sidemenu from '../Sidemenu';
import BubblePreview from './BubblePreview';
import FormPreview from './FormPreview';
import SuccessPreview from './SuccessPreview';
import { useAuth } from '../../contexts/AuthContext';
import { collection, getDoc, getDocs, doc, query, where, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

export default function ChatbubbleEditor(props) {
    const queryParams = new URLSearchParams(window.location.search)
    const defaultMode = queryParams.get("mode")

    const { currentUser } = useAuth();
    let user = currentUser.uid;
  
    const [currentTab, setCurrentTab] = useState(defaultMode);
    const [chatBubbleData, setChatBubbleData] = useState(props.chatBubbleData);

    const [newChange, setNewChange] = useState(true);

    const bubbleTextRef = useRef();
    const bubbleTextColorRef = useRef();
    const bubbleBackgroundColorRef = useRef();
    const formTitleRef = useRef();
    const formTextColorRef = useRef();
    const formDescriptionRef = useRef();
    const formButtonTextColorRef = useRef();
    const formButtonBackgroundColorRef = useRef();
    const formButtonTextRef = useRef();
    const formBackgroundColorRef = useRef();
    const successTitleRef = useRef();
    const successDescriptionRef = useRef();
    const successImageColorRef = useRef();
    const successTextColorRef = useRef();
    const successBackgroundColorRef = useRef();
    const [bubbleText, setBubbleText] = useState(props.chatBubbleData.data().bubbleText);
    const [bubbleBackgroundColor, setBubbleBackgroundColor] = useState(props.chatBubbleData.data().bubbleBackgroundColor);
    const [bubbleTextColor, setBubbleTextColor] = useState(props.chatBubbleData.data().bubbleTextColor);
    const [formTitle, setFormTitle] = useState(props.chatBubbleData.data().formTitle);
    const [formTextColor, setFormTextColor] = useState(props.chatBubbleData.data().formTextColor);
    const [formDescription, setFormDescription] = useState(props.chatBubbleData.data().formDescription);
    const [formButtonText, setFormButtonText] = useState(props.chatBubbleData.data().formButtonText);
    const [formButtonTextColor, setFormButtonTextColor] = useState(props.chatBubbleData.data().formButtonTextColor);
    const [formButtonBackgroundColor, setFormButtonBackgroundColor] = useState(props.chatBubbleData.data().formButtonBackgroundColor);
    const [formBackgroundColor, setFormBackgroundColor] = useState(props.chatBubbleData.data().formBackgroundColor);
    const [successTitle, setSuccessTitle] = useState(props.chatBubbleData.data().successTitle);
    const [successDescription, setSuccessDescription] = useState(props.chatBubbleData.data().successDescription);
    const [successImageColor, setSuccessImageColor] = useState(props.chatBubbleData.data().successImageColor);
    const [successTextColor, setSuccessTextColor] = useState(props.chatBubbleData.data().successTextColor);
    const [successBackgroundColor, setSuccessBackgroundColor] = useState(props.chatBubbleData.data().successBackgroundColor);
  

    function handleSave() {
        updateDoc(doc(db, 'users', props.uid, 'chat-bubbles', chatBubbleData.id), {
            bubbleText: bubbleText,
            bubbleTextColor: bubbleTextColor,
            bubbleBackgroundColor: bubbleBackgroundColor,
            formTitle: formTitle,
            formTextColor: formTextColor,
            formDescription: formDescription,
            formButtonText: formButtonText,
            formButtonTextColor: formButtonTextColor,
            formButtonBackgroundColor: formButtonBackgroundColor,
            formBackgroundColor: formBackgroundColor,
            successTitle: successTitle,
            successDescription: successDescription,
            successTextColor: successTextColor,
            successBackgroundColor: successBackgroundColor,
            successImageColor: successImageColor
        })
        setNewChange(true);
    }

    function handleChangeTab(mode) {
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?mode=' + mode;
        window.history.pushState({path:newurl},'',newurl);
        setCurrentTab(mode);
    }


    function handleChange(setState, ref) {
        setNewChange(false);
        setState(ref.current.value);
    }

    function handleCopyCode() {
        // let code = "https://solutions.snypemedia.com/chatbubble/embed?publickey=" + user + "&chatid=" + chatBubbleData.id;
        let code = `<script id="chatBubbleScript" defer type="module" src="https://solutions.snypemedia.com/chatbubble/embed.js" data-public-key=${user} data-chat-id=${chatBubbleData.id}></script>`
        navigator.clipboard.writeText(code);
    }

  
    if (currentTab == 'bubble') {
        return (
            <div className="page-wrapper">
                <Sidemenu currentTab='chatbubble'/>
                <div className="application-wrapper horizontal">
                    <div className="chat-bubble-preview">
                        <div className="preview">preview</div>
                        <BubblePreview bubbleText={bubbleText} bubbleBackgroundColor={bubbleBackgroundColor} bubbleTextColor={bubbleTextColor}/>
                    </div>
                    <div className="chat-bubble-editor-wrapper">
                        <div className="chat-bubble-menu-wrapper">
                            <a onClick={(e) => handleChangeTab('bubble')} className="chat-bubble-menu-link active">Bubble</a>
                            <a onClick={(e) => handleChangeTab('form')} className="chat-bubble-menu-link">Form</a>
                            <a onClick={(e) => handleChangeTab('success')} className="chat-bubble-menu-link">Success</a>
                        </div>
                        <div className="chat-bubble-edit-panel-wrapper">
                            <div className="chat-bubble-edit-properties-wrapper">
                                <div className="chat-bubble-edit-property-wrapper">
                                    <h3 className="property-title">Bubble Text</h3>
                                    <input className="property-input" type="text" value={bubbleText} ref={bubbleTextRef} onChange={(e) => handleChange(setBubbleText, bubbleTextRef)}/>
                                </div>
                                <div className="chat-bubble-edit-property-wrapper">
                                    <h3 className="property-title">Text Color</h3>
                                    <div className="color-input-wrapper property-input">
                                        <input className="property-input" type="color" value={bubbleTextColor} ref={bubbleTextColorRef} onChange={(e) => handleChange(setBubbleTextColor, bubbleTextColorRef)}/>
                                    </div>
                                </div>
                                <div className="chat-bubble-edit-property-wrapper">
                                    <h3 className="property-title">Background Color</h3>
                                    <div className="color-input-wrapper property-input">
                                        <input className="property-input" type="color" value={bubbleBackgroundColor} ref={bubbleBackgroundColorRef} onChange={(e) => handleChange(setBubbleBackgroundColor, bubbleBackgroundColorRef)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="chat-bubble-buttons-wrapper">
                                <button onClick={handleCopyCode} className="secundary-button chat-bubble-action-button">Copy Code</button>
                                <button disabled={newChange} className={"primary-button chat-bubble-action-button " + newChange} onClick={handleSave}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    else if (currentTab == 'form') {
        return (
            <div className="page-wrapper">
                <Sidemenu currentTab='chatbubble'/>
                <div className="application-wrapper horizontal">
                    <div className="chat-bubble-preview">
                        <div className="preview">preview</div>
                        <FormPreview 
                            formTitle={formTitle} 
                            formTextColor={formTextColor} 
                            formDescription={formDescription} 
                            formButtonText={formButtonText}
                            formButtonTextColor={formButtonTextColor}
                            formButtonBackgroundColor={formButtonBackgroundColor}
                            formBackgroundColor={formBackgroundColor}
                        />
                    </div>
                    <div className="chat-bubble-editor-wrapper">
                    <div className="chat-bubble-menu-wrapper">
                            <a onClick={(e) => handleChangeTab('bubble')} className="chat-bubble-menu-link">Bubble</a>
                            <a onClick={(e) => handleChangeTab('form')} className="chat-bubble-menu-link active">Form</a>
                            <a onClick={(e) => handleChangeTab('success')} className="chat-bubble-menu-link">Success</a>
                        </div>
                        <div className="chat-bubble-edit-panel-wrapper">
                            <div className="chat-bubble-edit-properties-wrapper">
                                <div className="chat-bubble-edit-property-wrapper">
                                    <h3 className="property-title">Title</h3>
                                    <input className="property-input" type="text" value={formTitle} ref={formTitleRef} onChange={(e) => handleChange(setFormTitle, formTitleRef)}/>
                                </div>
                                <div className="chat-bubble-edit-property-wrapper">
                                    <h3 className="property-title">Description</h3>
                                    <div className="" style={{ width: '100%' }}>
                                        <textarea className="property-input" type="text" value={formDescription} ref={formDescriptionRef} onChange={(e) => handleChange(setFormDescription, formDescriptionRef)}/>
                                    </div>
                                </div>
                                <div className="chat-bubble-edit-property-wrapper">
                                    <h3 className="property-title">Background Color</h3>
                                    <div className="color-input-wrapper property-input">
                                        <input className="property-input" type="color" value={formBackgroundColor} ref={formBackgroundColorRef} onChange={(e) => handleChange(setFormBackgroundColor, formBackgroundColorRef)}/>
                                    </div>
                                </div>
                                <div className="chat-bubble-edit-property-wrapper">
                                    <h3 className="property-title">Text Color</h3>
                                    <div className="color-input-wrapper property-input">
                                        <input className="property-input" type="color" value={formTextColor} ref={formTextColorRef} onChange={(e) => handleChange(setFormTextColor, formTextColorRef)}/>
                                    </div>
                                </div>
                                <div className="chat-bubble-edit-property-wrapper">
                                    <h3 className="property-title">Button Text</h3>
                                    <div className="">
                                        <input className="property-input" type="text" value={formButtonText} ref={formButtonTextRef} onChange={(e) => handleChange(setFormButtonText, formButtonTextRef)}/>
                                    </div>
                                </div>
                                <div className="chat-bubble-edit-property-wrapper">
                                    <h3 className="property-title">Button Text Color</h3>
                                    <div className="color-input-wrapper property-input">
                                        <input className="property-input" type="color" value={formButtonTextColor} ref={formButtonTextColorRef} onChange={(e) => handleChange(setFormButtonTextColor, formButtonTextColorRef)}/>
                                    </div>
                                </div>
                                <div className="chat-bubble-edit-property-wrapper">
                                    <h3 className="property-title">Button Background Color</h3>
                                    <div className="color-input-wrapper property-input">
                                        <input className="property-input" type="color" value={formButtonBackgroundColor} ref={formButtonBackgroundColorRef} onChange={(e) => handleChange(setFormButtonBackgroundColor, formButtonBackgroundColorRef)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="chat-bubble-buttons-wrapper">
                                <button onClick={handleCopyCode} className="secundary-button chat-bubble-action-button">Copy Code</button>
                                <button disabled={newChange} className={"primary-button chat-bubble-action-button " + newChange} onClick={handleSave}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    else if (currentTab == 'success') {
        return (
            <div className="page-wrapper">
                <Sidemenu currentTab='chatbubble'/>
                <div className="application-wrapper horizontal">
                    <div className="chat-bubble-preview">
                        <div className="preview">preview</div>
                        <SuccessPreview 
                            successTitle={successTitle}
                            successDescription={successDescription}
                            successTextColor={successTextColor}
                            successBackgroundColor={successBackgroundColor}
                            successImageColor={successImageColor}
                        />
                    </div>
                    <div className="chat-bubble-editor-wrapper">
                    <div className="chat-bubble-menu-wrapper">
                            <a onClick={(e) => handleChangeTab('bubble')} className="chat-bubble-menu-link">Bubble</a>
                            <a onClick={(e) => handleChangeTab('form')} className="chat-bubble-menu-link">Form</a>
                            <a onClick={(e) => handleChangeTab('success')} className="chat-bubble-menu-link active">Success</a>
                        </div>
                        <div className="chat-bubble-edit-panel-wrapper">
                            <div className="chat-bubble-edit-properties-wrapper">
                                <div className="chat-bubble-edit-property-wrapper">
                                    <h3 className="property-title">Title</h3>
                                    <input className="property-input" type="text" value={successTitle} ref={successTitleRef} onChange={(e) => handleChange(setSuccessTitle, successTitleRef)}/>
                                </div>
                                <div className="chat-bubble-edit-property-wrapper">
                                    <h3 className="property-title">Description</h3>
                                    <div className="" style={{ width: '100%' }}>
                                        <textarea className="property-input" type="text" value={successDescription} ref={successDescriptionRef} onChange={(e) => handleChange(setSuccessDescription, successDescriptionRef)}/>
                                    </div>
                                </div>
                                <div className="chat-bubble-edit-property-wrapper">
                                    <h3 className="property-title">Background Color</h3>
                                    <div className="color-input-wrapper property-input">
                                        <input className="property-input" type="color" value={successBackgroundColor} ref={successBackgroundColorRef} onChange={(e) => handleChange(setSuccessBackgroundColor, successBackgroundColorRef)}/>
                                    </div>
                                </div>
                                <div className="chat-bubble-edit-property-wrapper">
                                    <h3 className="property-title">Text Color</h3>
                                    <div className="color-input-wrapper property-input">
                                        <input className="property-input" type="color" value={successTextColor} ref={successTextColorRef} onChange={(e) => handleChange(setSuccessTextColor, successTextColorRef)}/>
                                    </div>
                                </div>
                                <div className="chat-bubble-edit-property-wrapper">
                                    <h3 className="property-title">Image Color</h3>
                                    <div className="color-input-wrapper property-input">
                                        <input className="property-input" type="color" value={successImageColor} ref={successImageColorRef} onChange={(e) => handleChange(setSuccessImageColor, successImageColorRef)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="chat-bubble-buttons-wrapper">
                                <button onClick={handleCopyCode} className="secundary-button chat-bubble-action-button">Copy Code</button>
                                <button disabled={newChange} className={"primary-button chat-bubble-action-button " + newChange} onClick={handleSave}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    else { 
        setCurrentTab('bubble') 
        return <></>
    }
}
