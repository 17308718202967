import React, { useRef, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import googleLogo from '../../images/google-logo.png';

export default function Login() {

    const emailRef = useRef();
    const passwordRef = useRef();
    const { loginWithEmail, authenticateWithGoogle } = useAuth();
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function handleSubmit(e) {
        e.preventDefault();

        setError('');
        setLoading(true);

        loginWithEmail(emailRef.current.value, passwordRef.current.value)
        .then((userCredential) => {
            navigate('/');
        })
        .catch((error) => {
            setError('Failed to sign in');
            console.error(error);
        }) 
        setLoading(false);

    }

    function handleGoogleSignin() {
        authenticateWithGoogle();
    }

    return (
        <div className="auth-page-wrapper">
        <div className="form-wrapper auth-form-wrapper sign-up-card">
            <h2 className="auth-header">Log in</h2>
            {error && <div className="error">{error}</div>}
            {/* <div onClick={handleGoogleSignin} className="sign-in-with-google">
                <img src={googleLogo} alt="" />
                <h2>Log in with Google</h2>
            </div> */}
            <form onSubmit={handleSubmit} className="auth-form sign-up-form">
                <div className="auth-form-group">
                    <label htmlFor="" className="auth-form-label">Email</label>
                    <input type="email" ref={emailRef} className="auth-form-input" required/>
                </div>
                <div className="auth-form-group">
                    <label htmlFor="" className="auth-form-label">Password</label>
                    <input type="password" ref={passwordRef} className="auth-form-input" required/>
                </div>
                <Link style={{ color: 'black' }} to='/signup'><button type="button" style={{ marginRight: '5px' }} className="secundary-button">Create Account</button></Link>
                <button disabled={loading} type="submit" className="primary-button button-stretch">Log in</button>
            </form>
        </div>
        {/* <div className="switch-auth-domain">
            Don't have an account yet? <Link style={{ color: 'var(--primary-clr)' }} to='/signup'>Create account</Link>
        </div> */}
        </div>
    )
}
