import React, { useState, useEffect } from 'react';
import '../../css/chatbubble.css';
import ChatbubbleEditor from './ChatbubbleEditor';
import Sidemenu from '../Sidemenu';
import { useAuth } from '../../contexts/AuthContext';
import { collection, getDoc, getDocs, doc, query, where } from 'firebase/firestore';
import { db } from '../../firebase';

export default function Chatbubble() {

    const { currentUser } = useAuth();
    let user = currentUser.uid;
  
    const [loading, setLoading] = useState(true);

    const [currentMode, setCurrentMode] = useState('editor');
    const [chatBubbleData, setChatBubbleData] = useState();

  
    useEffect(() => {
        getDocs(collection(db, 'users', user, 'chat-bubbles'))
        .then((docs) => {
            docs.forEach((doc) => {
                setChatBubbleData(doc);
            })
            setLoading(false);
        })
    }, [])
  
    if (loading) {
        return (
            <div className="page-wrapper">
                <Sidemenu currentTab='chatbubble'/>
                <div className="application-wrapper horizontal">
                </div>
            </div>
    )
    }

    if (currentMode == 'editor') {
        return (
            <ChatbubbleEditor chatBubbleData={chatBubbleData} uid={user}/>
      )
    }
}
