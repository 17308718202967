import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

// const app = firebase.initializeApp({
//     apiKey: "AIzaSyAT5KOF9wQbH23naKpVjkyKyra3bA4zq5k",
//     authDomain: "travel-expenses-6fff4.firebaseapp.com",
//     projectId: "travel-expenses-6fff4",
//     storageBucket: "travel-expenses-6fff4.appspot.com",
//     messagingSenderId: "136928141318",
//     appId: "1:136928141318:web:1fb7e494f1b9e2069f2387",
//     measurementId: "G-MDTL94BWHQ"
// })

// export const auth = app.auth();
// export default app;



// v2

const firebaseConfig = {
    apiKey: "AIzaSyC8bFz5LV-Hhkq1BLoNoO1JSzLMe-kGfAw",
    authDomain: "snypemedia-62b10.firebaseapp.com",
    projectId: "snypemedia-62b10",
    storageBucket: "snypemedia-62b10.appspot.com",
    messagingSenderId: "294172192530",
    appId: "1:294172192530:web:92be30ca0ae2c0ff58cdcb",
    measurementId: "G-DKCCTR6VM0"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const googleAuthProvider = new GoogleAuthProvider();
export const db = getFirestore();
export const functions = getFunctions(app, 'us-west2');
export default app;